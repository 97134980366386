*,
*:focus {
  outline: 0;
}

.owl-theme .owl-controls .owl-page {
  display: inline-block;
  zoom: 1;
  position: relative;
}

.kd_input_full_width {
  width: 100%;
}

.kd_input_one {
  width: 100%;
}

.kd_input_two {
  width: 48%;
  margin-right: 25px;
  display: inline-block;
}

.kd_input_three {
  width: 33%;
  display: inline-block;
}

.kd_input_two:nth-child(even) {
  margin-right: 0;
}

.clickable {
  cursor: pointer;
}


@font-face {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url(/src/assets/font/gregular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url(/src/assets/font/gsemibold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}



img {
  max-width: 100%;
  vertical-align: bottom;
}

.kd_pad_rl {
  padding: 0 15px;
}

.kd_center {
  text-align: center;
}

.kd_inline_block {
  display: inline-block !important;
}

.kd_padding_tb {
  padding: 15px 0px;
}

.kd_dis_unset {
  display: unset;
}

.kd_disBlock {
  display: block;
  width: 100%;
  overflow: auto;
}

.kd_dis_none {
  display: none !important;
}

.kd_bg_white {
  background: #fff;
}

.kd_padding_horizontal {
  padding: 6px 0px;
}

.tr-answer a {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  text-decoration: underline;
}

a:focus,
a:hover {
  text-decoration: none;
}


.container {
  margin: 0 auto;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .container {
    margin: 0 10px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}


.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

*,
:after,
:before {
  box-sizing: border-box;
}


.to-top {
  background: #713779;
  position: fixed;
  bottom: 16px;
  right: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 32px; */
  color: #1f1f1f;
  text-decoration: none;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s;
}

.to-top.active {
  bottom: 32px;
  pointer-events: auto;
  opacity: 1;
  z-index: 999;
}

.grecaptcha-badge {
  display: none !important;
}


/*common css End*/

/*common effect for smooth function of ui css*/

.kd_xsVisible {
  display: none;
}

.kd_lgVisible {
  display: block;
}

.list-inline {
  margin: 0 -6px;
  /* font-size: 0; */
  padding: 0;
  list-style-type: none;
}

.list-inline>li {
  padding: 0 6px;
  /* font-size: 14px; */
  display: inline-block;
}

.brand-logo img {
  width: 12em;
}

.sidebar_icon {
  height: 1.2rem !important;
  width: 1.2rem !important;
}


.list-unstyled span {
  font-size: 0.9em;
  text-align: center;
}

ul:not(.browser-default)>li {
  list-style-type: circle !important;
}

.form-control {
  position: relative;
  line-height: 18px;
  color: #acacac;
  display: block;
  width: 100%;
  padding: 6px 22px;
  background-color: #fff;
  border: solid 2px #d6d8da;
  border-radius: 5px;
  box-shadow: none;
}

.hide {
  display: none !important;
}

.kd_cur {
  cursor: pointer;
}

.kd_cur:hover {
  text-decoration: underline;
}

.kd_textA {
  border-radius: 5px;
  background-color: #fff;
  border: solid 2px #d6d8da;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  padding: 6px 12px;
  min-height: 40px;
  resize: vertical;
  display: block;
  /* font-size: 12px; */
  line-height: 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
}

.kd_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 57px;
  width: 100vw;
}

a.kd_logo>img {
  width: 162px;
  height: auto;
}

.MuiListItemIcon-root{
  color: white !important;
  min-width: 40px !important;
}
.MuiListItem-gutters{
  padding: 10px 10px !important;
}

/* 
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  border-radius: 20px;
}

::-webkit-scrollbar-button {
  display: none;
  width: 50px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: #c4c4c4;
  height: 20px;
}

::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
  height: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #b10854;
  border-radius: 10px;
  width: 1px;
  height: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c4c4c4;
  height: 20px;
}

::-webkit-scrollbar-corner {
  display: none;
  height: 20px;
}

::-webkit-resizer {
  display: none;
  height: 20px;
} */
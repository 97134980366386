.pos-ab {
    position: absolute;
}

.d-inline-block {
    display: inline-block !important;
    text-align: right;
    top: 12px;
    position: relative;
}

.navbar .user {
    color: #7d7d7d;
    position: relative;
}

.navbar .navbar-right {
    flex-basis: 40%;
    text-align: right;
}

.navbar-right .name {
    /* font-size: 14px; */
    font-weight: 400;
    padding-top: 4px;
    margin-bottom: 5px;
    padding-right: 5px;
    font-weight: 600;
}

.kd-employee-id {
    display: block;
}

.kd-employee-id {
    color: #7d7d7d;
}
